import Plyr from 'plyr';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

function initializePlyr() {
    document.querySelectorAll('.podcast-player').forEach((player) => {
        if (!player.hasAttribute('data-plyr-initialized')) {
            new Plyr(player);
            player.setAttribute('data-plyr-initialized', 'true');
        }
    });
}

// Ensure Plyr initializes on Turbo page loads
document.addEventListener('turbo:load', initializePlyr);
